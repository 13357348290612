import React from 'react'
import DOMPurify from 'dompurify'
import * as Scroller from 'react-scroll'


export default function PandP() {

    React.useEffect(() => {
        Scroller.animateScroll.scrollToTop()
    }, [])

    const theHTML = `<style>
        [data-custom-class='body'],
        [data-custom-class='body'] * {
            background: #ffffff !important;
        }
    
        [data-custom-class='title'],
        [data-custom-class='title'] * {
            font-family: Arial !important;
            font-size: 26px !important;
            color: #000000 !important;
        }
    
        [data-custom-class='subtitle'],
        [data-custom-class='subtitle'] * {
            font-family: Arial !important;
            color: #595959 !important;
            font-size: 14px !important;
        }
    
        [data-custom-class='heading_1'],
        [data-custom-class='heading_1'] * {
            font-family: Arial !important;
            font-size: 19px !important;
            color: #000000 !important;
        }
    
        [data-custom-class='heading_2'],
        [data-custom-class='heading_2'] * {
            font-family: Arial !important;
            font-size: 17px !important;
            color: #000000 !important;
        }
    
        [data-custom-class='body_text'],
        [data-custom-class='body_text'] * {
            color: #595959 !important;
            font-size: 14px !important;
            font-family: Arial !important;
        }
    
        [data-custom-class='link'],
        [data-custom-class='link'] * {
            color: #3030F1 !important;
            font-size: 14px !important;
            font-family: Arial !important;
            word-break: break-word !important;
        }
    </style>
    
    <div data-custom-class="body" style="color: #FF731599 !important">
    <div><strong><span style="font-size: 26px;"><span data-custom-class="title">PRIVACY NOTICE</span></span></strong>
    </div>
    <div><br></div>
    <div><span style="color: rgb(127, 127, 127);"><strong><span style="font-size: 15px;"><span
                        data-custom-class="subtitle">Last updated <bdt class="question">September 07, 2022</bdt>
                        </span></span></strong></span></div>
    <div><br></div>
    <div><br></div>
    <div><br></div>
    <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">This privacy notice
                    for <bdt class="question">Swift Recharge inc</bdt><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                        </span></span> (doing business as <span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text">
                            <bdt class="question">Swift Recharge)</bdt>
                            <bdt class="statement-end-if-in-editor"></bdt>
                        </span></span> (<bdt class="block-component"></bdt>"<bdt class="block-component"></bdt>
                    <bdt class="question"><strong>Swift Recharge</strong></bdt>
                    <bdt class="else-block"></bdt>," "<strong>we</strong>," "<strong>us</strong>," or
                    "<strong>our</strong>"<bdt class="statement-end-if-in-editor"></bdt>
                </span><span data-custom-class="body_text">), describes how and why we might collect, store, use, and/or
                    share (<bdt class="block-component"></bdt>"<strong>process</strong>"<bdt
                        class="statement-end-if-in-editor"></bdt>) your information when you use our services (<bdt
                        class="block-component"></bdt>"<strong>Services</strong>"<bdt
                        class="statement-end-if-in-editor"></bdt>), such as when you:</span></span></span><span
            style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span data-custom-class="body_text"><span
                        style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                        </span></span></span></span></span></span></span></span></li>
        </ul>
        <div>
            <bdt class="block-component"><span style="font-size: 15px;"><span style="font-size: 15px;"><span
                            style="color: rgb(127, 127, 127);"><span data-custom-class="body_text"><span
                                    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                        <bdt class="block-component"></bdt>
                                    </span></span></span></span></span></span></bdt>
        </div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Download
                            and use<bdt class="block-component"></bdt> our mobile application<bdt
                                class="block-component"></bdt> (<bdt class="question">swift recharge )<span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);">
                                                                <bdt class="statement-end-if-in-editor">,</bdt>
                                                            </span></span></span></span></span></span></span></span>
                            </bdt></span><span data-custom-class="body_text"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                                                <bdt class="statement-end-if-in-editor">
                                                    <bdt class="block-component"> or any other application of ours that
                                                        links to this privacy notice</bdt>
                                                </bdt>
                                            </span></span></span></span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
                        data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text">
                                <bdt class="block-component"></bdt>
                            </span></span></span></span></span></div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Engage with
                            us in other related ways, including any sales, marketing, or events<span
                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                        data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                style="color: rgb(89, 89, 89);">
                                                <bdt class="statement-end-if-in-editor"></bdt>
                                            </span></span></span></span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
                        data-custom-class="body_text"><strong>Questions or concerns? </strong>Reading this privacy
                        notice will help you understand your privacy rights and choices. If you do not agree with our
                        policies and practices, please do not use our Services. If you still have any questions or
                        concerns, please contact us at <bdt class="question">info@swiftrecharge.ng</bdt>
                        .</span></span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><strong><span style="font-size: 15px;"><span
                        data-custom-class="heading_1">SUMMARY OF KEY POINTS</span></span></strong></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                    data-custom-class="body_text"><strong><em>This summary provides key points from our privacy notice,
                            but you can find out more details about any of these topics by clicking the link following
                            each key point or by using our table of contents below to find the section you are looking
                            for. You can also click </em></strong></span></span><a data-custom-class="link"
                href="#toc"><span style="font-size: 15px;"><span
                        data-custom-class="body_text"><strong><em>here</em></strong></span></span></a><span
                style="font-size: 15px;"><span data-custom-class="body_text"><strong><em> to go directly to our table of
                            contents.</em></strong></span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What
                        personal information do we process?</strong> When you visit, use, or navigate our Services, we
                    may process personal information depending on how you interact with <bdt class="block-component">
                    </bdt>
                    <bdt class="question">Swift Recharge</bdt>
                    <bdt class="else-block"></bdt> and the Services, the choices you make, and the products and features
                    you use. Click
                </span></span><a data-custom-class="link" href="#personalinfo"><span style="font-size: 15px;"><span
                        data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                    data-custom-class="body_text"> to learn more.</span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we
                        process any sensitive personal information?</strong>
                    <bdt class="block-component"></bdt>We do not process sensitive personal information.<bdt
                        class="else-block"></bdt>
                </span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we
                        receive any information from third parties?</strong>
                    <bdt class="block-component"></bdt>We may receive information from public databases, marketing
                    partners, social media platforms, and other outside sources. Click
                </span></span><a data-custom-class="link" href="#othersources"><span style="font-size: 15px;"><span
                        data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                    data-custom-class="body_text"> to learn more.<bdt class="statement-end-if-in-editor"></bdt>
                    </span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do
                        we process your information?</strong> We process your information to provide, improve, and
                    administer our Services, communicate with you, for security and fraud prevention, and to comply with
                    law. We may also process your information for other purposes with your consent. We process your
                    information only when we have a valid legal reason to do so. Click </span></span><a
                data-custom-class="link" href="#infouse"><span style="font-size: 15px;"><span
                        data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                    data-custom-class="body_text"> to learn more.</span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>In
                        what situations and with which <bdt class="block-component"></bdt>parties do we share personal
                        information?</strong> We may share information in specific situations and with specific <bdt
                        class="block-component"></bdt>third parties. Click </span></span><a data-custom-class="link"
                href="#whoshare"><span style="font-size: 15px;"><span
                        data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                    data-custom-class="body_text"> to learn more.<bdt class="block-component"></bdt></span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do
                        we keep your information safe?</strong> We have <bdt class="block-component"></bdt>
                    organizational<bdt class="statement-end-if-in-editor"></bdt> and technical processes and procedures
                    in place to protect your personal information. However, no electronic transmission over the internet
                    or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                    guarantee that hackers, cybercriminals, or other <bdt class="block-component"></bdt>unauthorized<bdt
                        class="statement-end-if-in-editor"></bdt> third parties will not be able to defeat our security
                    and improperly collect, access, steal, or modify your information. Click </span></span><a
                data-custom-class="link" href="#infosafe"><span style="font-size: 15px;"><span
                        data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                    data-custom-class="body_text"> to learn more.<bdt class="statement-end-if-in-editor"></bdt>
                    </span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What
                        are your rights?</strong> Depending on where you are located geographically, the applicable
                    privacy law may mean you have certain rights regarding your personal information. Click
                </span></span><a data-custom-class="link" href="#privacyrights"><span style="font-size: 15px;"><span
                        data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                    data-custom-class="body_text"> to learn more.</span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do
                        you exercise your rights?</strong> The easiest way to exercise your rights is by filling out our
                    data subject request form available <bdt class="block-component"></bdt>here: <bdt class="question">
                        <a href="http://www.swiftrecharge.ng" target="_blank"
                            data-custom-class="link">http://www.swiftrecharge.ng</a></bdt>
                    <bdt class="else-block"></bdt>, or by contacting us. We will consider and act upon any request in
                    accordance with applicable data protection laws.
                </span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">Want to learn
                    more about what <bdt class="block-component"></bdt>
                    <bdt class="question">Swift Recharge</bdt>
                    <bdt class="else-block"></bdt> does with any information we collect? Click
                </span></span><a data-custom-class="link" href="#toc"><span style="font-size: 15px;"><span
                        data-custom-class="body_text">here</span></span></a><span style="font-size: 15px;"><span
                    data-custom-class="body_text"> to review the notice in full.</span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><br></div>
        <div id="toc" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                    style="color: rgb(127, 127, 127);"><span style="color: rgb(0, 0, 0);"><strong><span
                                data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></span></span></span>
        </div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                    href="#infocollect"><span style="color: rgb(89, 89, 89);">1. WHAT INFORMATION DO WE
                        COLLECT?</span></a></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infouse"><span
                        style="color: rgb(89, 89, 89);">2. HOW DO WE PROCESS YOUR INFORMATION?<bdt
                            class="block-component"></bdt></span></a></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                    href="#legalbases"><span style="color: rgb(89, 89, 89);">3. <span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                                INFORMATION?</span></span>
                        <bdt class="statement-end-if-in-editor"></bdt>
                    </span></a></span></div>
                    <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                    href="#whoshare"><span style="color: rgb(89, 89, 89);">4. <span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                                INFORMATION</span></span>
                        <bdt class="statement-end-if-in-editor"></bdt>
                    </span></a></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#cookies"><span
                        style="color: rgb(89, 89, 89);">5. DO WE USE COOKIES AND OTHER TRACKING
                        TECHNOLOGIES?</span></a><span style="color: rgb(127, 127, 127);"><span
                        style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="color: rgb(89, 89, 89);">
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </span></span><span data-custom-class="body_text"><span
                                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                                        style="color: rgb(89, 89, 89);">
                                        <bdt class="block-component"></bdt>
                                    </span></span></span></span></span></span></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                    href="#sociallogins"><span style="color: rgb(89, 89, 89);"><span
                            style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">6. HOW DO WE HANDLE
                                YOUR SOCIAL LOGINS?</span></span></span></a><span
                    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                    style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">
                                        <bdt class="statement-end-if-in-editor"></bdt>
                                    </span></span>
                                <bdt class="block-component"></bdt>
                            </span></span></span></span></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                    href="#inforetain"><span style="color: rgb(89, 89, 89);">7. HOW LONG DO WE KEEP YOUR
                        INFORMATION?</span></a><span style="color: rgb(127, 127, 127);"><span
                        style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">
                                    <bdt class="block-component"></bdt>
                                </span></span></span></span></span></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infosafe"><span
                        style="color: rgb(89, 89, 89);">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a><span
                    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                <bdt class="statement-end-if-in-editor"></bdt>
                                <bdt class="block-component"></bdt>
                            </span></span></span></span></span></div>
                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#privacyrights"><span
                                        style="color: rgb(89, 89, 89);">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></a></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#DNT"><span
                        style="color: rgb(89, 89, 89);">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                    href="#caresidents"><span style="color: rgb(89, 89, 89);">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC
                        PRIVACY RIGHTS?</span></a></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
                    href="#policyupdates"><span style="color: rgb(89, 89, 89);">12. DO WE MAKE UPDATES TO THIS
                        NOTICE?</span></a></span></div>
        <div style="line-height: 1.5;"><a data-custom-class="link" href="#contact"><span
                    style="color: rgb(89, 89, 89); font-size: 15px;">13. HOW CAN YOU CONTACT US ABOUT THIS
                    NOTICE?</span></a></div>
        <div style="line-height: 1.5;"><a data-custom-class="link" href="#request"><span
                    style="color: rgb(89, 89, 89);">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                    YOU?</span></a></div>
        <div style="line-height: 1.5;"><br></div>
        <div id="infocollect" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">1. WHAT
                                        INFORMATION DO WE COLLECT?</span></strong></span></span></span></span></span>
        </div>
        <div style="line-height: 1.5;"><br></div>
        <div id="personalinfo" style="line-height: 1.5;"><span data-custom-class="heading_2"
                style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><strong>Personal information you disclose to
                        us</strong></span></span></div>
        <div>
            <div><br></div>
            <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                        style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        data-custom-class="body_text"><strong><em>In
                                                Short:</em></strong></span></span></span></span><span
                            data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        data-custom-class="body_text"><strong><em> </em></strong><em>We collect personal
                                            information that you provide to
                                            us.</em></span></span></span></span></span></span></div>
        </div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We collect
                        personal information that you voluntarily provide to us when you <span style="font-size: 15px;">
                            <bdt class="block-component"></bdt>
                        </span>register on the Services, </span><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><span style="font-size: 15px;">
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </span></span><span data-custom-class="body_text">express an interest in obtaining
                            information about us or our products and Services, when you participate in activities on the
                            Services, or otherwise when you contact us.</span></span></span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;">
                            <bdt class="block-component"></bdt>
                        </span></span></span></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        data-custom-class="body_text"><strong>Personal Information Provided by You.</strong> The
                        personal information that we collect depends on the context of your interactions with us and the
                        Services, the choices you make, and the products and features you use. The personal information
                        we collect may include the following:<span style="font-size: 15px;"><span
                                data-custom-class="body_text">
                                <bdt class="forloop-component"></bdt>
                            </span></span></span></span></span></div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px;"><span data-custom-class="body_text">
                                    <bdt class="question">names</bdt>
                                </span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="forloop-component"></bdt>
                            </span></span></span></span></span></div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px;"><span data-custom-class="body_text">
                                    <bdt class="question">phone numbers</bdt>
                                </span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="forloop-component"></bdt>
                            </span></span></span></span></span></div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px;"><span data-custom-class="body_text">
                                    <bdt class="question">email addresses</bdt>
                                </span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="forloop-component"></bdt>
                            </span></span></span></span></span></div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px;"><span data-custom-class="body_text">
                                    <bdt class="question">usernames</bdt>
                                </span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="forloop-component"></bdt>
                            </span></span></span></span></span></div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px;"><span data-custom-class="body_text">
                                    <bdt class="question">passwords</bdt>
                                </span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="forloop-component"></bdt>
                            </span></span></span></span></span></div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px;"><span data-custom-class="body_text">
                                    <bdt class="question">billing addresses</bdt>
                                </span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="forloop-component"></bdt>
                            </span></span></span></span></span></div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px;"><span data-custom-class="body_text">
                                    <bdt class="question">debit/credit card numbers</bdt>
                                </span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="forloop-component"></bdt>
                            </span><span data-custom-class="body_text">
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </span></span></span></span></span></div>
        <div id="sensitiveinfo" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                    data-custom-class="body_text"><strong>Sensitive Information.</strong>
                    <bdt class="block-component"></bdt>We do not process sensitive information.
                </span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">
                    <bdt class="else-block"></bdt>
                </span></span><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="block-component"></bdt>
                            </span></span></span></span></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Payment
                            Data.</strong> We may collect data necessary to process your payment if you make purchases,
                        such as your payment instrument number (such as a credit card number), and the security code
                        associated with your payment instrument. All payment data is stored by<bdt
                            class="forloop-component"></bdt><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    data-custom-class="body_text"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text">
                                            <bdt class="block-component"></bdt>
                                        </span></span></span></span></span>
                        <bdt class="question">paystack</bdt><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    data-custom-class="body_text"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                        data-custom-class="body_text">
                                                        <bdt class="block-component"></bdt>
                                                    </span></span></span><span data-custom-class="body_text"><span
                                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><span
                                                                style="font-size: 15px;"><span
                                                                    style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                style="font-size: 15px;"><span
                                                                                    data-custom-class="body_text">
                                                                                    <bdt class="forloop-component">
                                                                                    </bdt>
                                                                                </span></span></span></span></span></span></span></span></span></span></span></span>.
                                    You may find their privacy notice link(s) here:<span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">
                                                <bdt class="forloop-component"></bdt><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span></span></span></span>
                                            </span></span></span>
                                    <bdt class="question"><a href="https://paystack.com/terms" target="_blank"
                                            data-custom-class="link">https://paystack.com/terms</a></bdt><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="block-component"></bdt>
                                                                            </span></span></span></span></span></span></span></span>
                                                <bdt class="forloop-component"></bdt><span
                                                    style="font-size: 15px;"><span
                                                        data-custom-class="body_text">.</span></span>
                                            </span></span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span data-custom-class="body_text">
                                <bdt class="statement-end-if-in-editor">
                                    <bdt class="block-component"></bdt>
                                </bdt>
                            </span></span></span></span></span></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Social
                            Media Login Data. </strong>We may provide you with the option to register with us using your
                        existing social media account details, like your Facebook, Twitter, or other social media
                        account. If you choose to register in this way, we will collect the information described in the
                        section called <bdt class="block-component"></bdt>"<bdt class="statement-end-if-in-editor">
                        </bdt><span style="font-size: 15px;"><span data-custom-class="body_text"><span
                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a
                                            data-custom-class="link" href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL
                                            LOGINS?</a></span></span></span></span>
                        <bdt class="block-component"></bdt>"<bdt class="statement-end-if-in-editor"></bdt> below.
                    </span></span></span></div>
        <div style="line-height: 1.5;"><br></div>
        <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;">
                            <bdt class="statement-end-if-in-editor">
                                <bdt class="statement-end-if-in-editor"></bdt>
                            </bdt>
                        </span></span></span></span>
            <bdt class="block-component">
                <bdt class="block-component"></bdt>
            </bdt>
        </div>
        <div style="line-height: 1.5;"><span data-custom-class="body_text"><span
                    style="font-size: 15px;"><strong>Application Data.</strong> If you use our application(s), we also
                    may collect the following information if you choose to provide us with access or permission:<bdt
                        class="block-component"></bdt></span></span></div>
        <ul>
            <li style="line-height: 1.5;"><span data-custom-class="body_text"><span
                        style="font-size: 15px;"><em>Geolocation Information.</em> We may request access or permission
                        to track location-based information from your mobile device, either continuously or while you
                        are using our mobile application(s), to provide certain location-based services. If you wish to
                        change our access or permissions, you may do so in your device's settings.<bdt
                            class="statement-end-if-in-editor"></bdt></span></span></li>
        </ul>
        <div style="line-height: 1.5;">
            <bdt class="block-component"><span style="font-size: 15px;"><span
                        data-custom-class="body_text"></span></span></bdt>
        </div>
        <ul>
            <li style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><em>Mobile
                            Device Access.</em> We may request access or permission to certain features from your mobile
                        device, including your mobile device's <bdt class="forloop-component"></bdt>
                        <bdt class="question">camera</bdt>, <bdt class="forloop-component"></bdt>
                        <bdt class="question">storage</bdt>, <bdt class="forloop-component"></bdt>
                        <bdt class="question">sms messages</bdt>, <bdt class="forloop-component"></bdt>
                        <bdt class="question">calendar</bdt>, <bdt class="forloop-component"></bdt>
                        <bdt class="question">sensors</bdt>, <bdt class="forloop-component"></bdt>
                        <bdt class="question">social media accounts</bdt>, <bdt class="forloop-component"></bdt>
                        <bdt class="question">reminders</bdt>, <bdt class="forloop-component"></bdt>and other features.
                        If you wish to change our access or permissions, you may do so in your device's settings.<bdt
                            class="statement-end-if-in-editor"></bdt>
                    </span></span></li>
        </ul>
        <div style="line-height: 1.5;">
            <bdt class="block-component"><span style="font-size: 15px;"><span data-custom-class="body_text"></bdt>
            </span></span></li>
            </ul>
            <div style="line-height: 1.5;">
                <bdt class="block-component"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"></span></span></bdt>
            </div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><em>Push Notifications.</em> We may request to send you push
                            notifications regarding your account or certain features of the application(s). If you wish
                            to opt out from receiving these types of communications, you may turn them off in your
                            device's settings.<bdt class="statement-end-if-in-editor"></bdt></span></span></li>
            </ul>
            <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">This
                        information is primarily needed to maintain the security and operation of our application(s),
                        for troubleshooting, and for our internal analytics and reporting purposes.</span></span></div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;">
                <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"></span></span></bdt>
            </div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">All
                            personal information that you provide to us must be true, complete, and accurate, and you
                            must notify us of any changes to such personal information.</span></span></span></div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                        </span></span></span></div>
            <div style="line-height: 1.5;"><span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span
                        style="font-size: 15px;"><strong>Information automatically collected</strong></span></span>
            </div>
            <div>
                <div><br></div>
                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><strong><em>In
                                                    Short:</em></strong></span></span></span></span><span
                                data-custom-class="body_text"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><strong><em> </em></strong><em>Some
                                                information — such as your Internet Protocol (IP) address and/or browser
                                                and device characteristics — is collected automatically when you visit
                                                our Services.</em></span></span></span></span></span></span></div>
            </div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We
                            automatically collect certain information when you visit, use, or navigate the Services.
                            This information does not reveal your specific identity (like your name or contact
                            information) but may include device and usage information, such as your IP address, browser
                            and device characteristics, operating system, language preferences, referring URLs, device
                            name, country, location, information about how and when you use our Services, and other
                            technical information. This information is primarily needed to maintain the security and
                            operation of our Services, and for our internal analytics and reporting
                            purposes.</span></span></span></div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                        </span></span></span></div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Like many
                            businesses, we also collect information through cookies and similar technologies. <bdt
                                class="block-component"></bdt>
                            <bdt class="block-component"></bdt>
                        </span></span></span></div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                            <bdt class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></bdt>
                        </span><span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                        </span></span></span></div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">The
                            information we collect includes:<bdt class="block-component"></bdt></span></span></span>
            </div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><em>Log
                                    and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and
                                performance information our servers automatically collect when you access or use our
                                Services and which we record in log files. Depending on how you interact with us, this
                                log data may include your IP address, device information, browser type, and settings and
                                information about your activity in the Services<span style="font-size: 15px;">
                                </span>(such as the date/time stamps associated with your usage, pages and files viewed,
                                searches, and other actions you take such as which features you use), device event
                                information (such as system activity, error reports (sometimes called <bdt
                                    class="block-component"></bdt>"crash dumps"<bdt class="statement-end-if-in-editor">
                                </bdt>), and hardware settings).<span style="font-size: 15px;"><span
                                        style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                </span></span></span></span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;">
                <bdt class="block-component"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"></span></span></bdt>
            </div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text"><em>Device Data.</em> We collect device data such as
                                information about your computer, phone, tablet, or other device you use to access the
                                Services. Depending on the device used, this device data may include information such as
                                your IP address (or proxy server), device and application identification numbers,
                                location, browser type, hardware model, Internet service provider and/or mobile carrier,
                                operating system, and system configuration information.<span
                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);">
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                </span></span></span></span></span></span></span></span></li>
            </ul>
            <div style="line-height: 1.5;">
                <bdt class="block-component"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"></span></span></bdt>
            </div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text"><em>Location Data.</em> We collect location data such as
                                information about your device's location, which can be either precise or imprecise. How
                                much information we collect depends on the type and settings of the device you use to
                                access the Services. For example, we may use GPS and other technologies to collect
                                geolocation data that tells us your current location (based on your IP address). You can
                                opt out of allowing us to collect this information either by refusing access to the
                                information or by disabling your Location setting on your device. However, if you choose
                                to opt out, you may not be able to use certain aspects of the Services.<span
                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);">
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                </span></span></span></span></span></span></span></span></li>
            </ul>
            <div>
                <bdt class="block-component"><span style="font-size: 15px;"></bdt>
                </bdt>
                <bdt class="statement-end-if-in-editor"></bdt>
                </bdt></span><span data-custom-class="body_text"><span
                        style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                                style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">
                                    <bdt class="statement-end-if-in-editor">
                                        <bdt class="block-component"></bdt>
                                    </bdt>
                                </span></span></span></span></span></span></span>
            </div>
            <div id="othersources" style="line-height: 1.5;"><span data-custom-class="heading_2"
                    style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><strong>Information collected from other
                            sources</strong></span></span></div>
            <div>
                <div><br></div>
                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We may
                                                collect limited data from public databases, marketing partners, <span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px;"><em><span
                                                                data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>social media
                                                                platforms, <bdt class="statement-end-if-in-editor">
                                                                </bdt>
                                                            </span></em></span></span>and other outside
                                                sources.</em></span></span></span></span></span></span></div>
            </div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">In order to
                            enhance our ability to provide relevant marketing, offers, and services to you and update
                            our records, we may obtain information about you from other sources, such as public
                            databases, joint marketing partners, affiliate programs, data providers,<span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text">
                                        <bdt class="block-component"></bdt> social media platforms,<bdt
                                            class="statement-end-if-in-editor"></bdt>
                                    </span></span> </span>and from other third parties. This information includes
                            mailing addresses, job titles, email addresses, phone numbers, intent data (or user <bdt
                                class="block-component"></bdt>behavior<bdt class="statement-end-if-in-editor"></bdt>
                            data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and
                            custom profiles, for purposes of targeted advertising and event promotion.<span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px;"><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>
                                                </span></span> </span>If you interact with us on a social media platform
                                        using your social media account (e.g.<bdt class="block-component"></bdt>,<bdt
                                            class="statement-end-if-in-editor"></bdt> Facebook or Twitter), we receive
                                        personal information about you such as your name, email address, and gender. Any
                                        personal information that we collect from your social media account depends on
                                        your social media account's privacy settings.</span></span></span></span><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                    style="font-size: 15px;"><span data-custom-class="body_text"><span
                                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                    data-custom-class="body_text"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                            style="font-size: 15px;"><span
                                                                data-custom-class="body_text">
                                                                <bdt class="statement-end-if-in-editor"></bdt>
                                                            </span></span></span></span></span></span></span></span></span></span></span></span>
            </div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                                style="color: rgb(89, 89, 89);">
                                                <bdt class="block-component"><span
                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                            data-custom-class="body_text"><span
                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="statement-end-if-in-editor">
                                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                                    </bdt>
                                                                </span></span></span></span></bdt>
                                            </span></span></span></span></span></span></span></span><span
                    style="font-size: 15px;"><span data-custom-class="body_text">
                        <bdt class="block-component"></bdt>
                    </span></span></div>
            <div id="infouse" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                    style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">2. HOW DO
                                            WE PROCESS YOUR
                                            INFORMATION?</span></strong></span></span></span></span></span></div>
            <div>
                <div style="line-height: 1.5;"><br></div>
                <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We
                                                process your information to provide, improve, and administer our
                                                Services, communicate with you, for security and fraud prevention, and
                                                to comply with law. We may also process your information for other
                                                purposes with your
                                                consent.</em></span></span></span></span></span></span></div>
            </div>
            <div style="line-height: 1.5;"><br></div>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>We
                                process your personal information for a variety of reasons, depending on how you
                                interact with our Services, including:</strong>
                            <bdt class="block-component"></bdt>
                        </span></span></span></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text"><strong>To facilitate account creation and authentication
                                    and otherwise manage user accounts. </strong>We may process your information so you
                                can create and log in to your account, as well as keep your account in working
                                order.<span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                                </span></span></span></span></span></span></span></span></span></span></span></span>
                </li>
            </ul>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                        </span></span></span></div>
            <ul>
                <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text"><strong>To deliver and facilitate delivery of services to
                                    the user. </strong>We may process your information to provide you with the requested
                                service.<span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;"><span
                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="statement-end-if-in-editor">
                                                                                </bdt>
                                                                            </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
                </li>
            </ul>
            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                        </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
                </li>
                </ul>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                <bdt class="block-component"></bdt>
                            </span></span></span></div>
                <ul>
                    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    data-custom-class="body_text"><strong>To respond to user inquiries/offer support to
                                        users. </strong>We may process your information to respond to your inquiries and
                                    solve any potential issues you might have with the requested service.<span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><span
                                                                style="font-size: 15px;"><span
                                                                    style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">
                                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                                    </span></span></span></span></span></span></span></span></span></span></span></span>
                    </li>
                </ul>
                <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                <bdt class="block-component"></bdt>
                            </span></span></span></li>
                    </ul>
                    <div style="line-height: 1.5;">
                        <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                    </div>
                    <ul>
                        <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        data-custom-class="body_text"><strong>To send administrative information to you.
                                        </strong>We may process your information to send you details about our products
                                        and services, changes to our terms and policies, and other similar
                                        information.<span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                </span></span></span></span></span></span></li>
                    </ul>
                    <div style="line-height: 1.5;">
                        <bdt class="block-component"><span style="font-size: 15px;"></bdt>
                        </span></span></span></span></span></span></span></span></span></li>
                        </ul>
                        <div style="line-height: 1.5;">
                            <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                        </div>
                        <ul>
                            <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            data-custom-class="body_text"><strong>To <bdt class="block-component"></bdt>
                                                fulfill<bdt class="statement-end-if-in-editor"></bdt> and manage your
                                                orders. </strong>We may process your information to <bdt
                                                class="block-component"></bdt>fulfill<bdt
                                                class="statement-end-if-in-editor"></bdt> and manage your orders,
                                            payments, returns, and exchanges made through the Services.<span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text"><span
                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="statement-end-if-in-editor">
                                                                                </bdt>
                                                                            </span></span></span></span></span></span></span></span></span></span></span></span>
                            </li>
                        </ul>
                        <div style="line-height: 1.5;">
                            <bdt class="block-component"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"></span></span></bdt>
                            </li>
                            </ul>
                            <p style="font-size: 15px; line-height: 1.5;">
                                <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                            </p>
                            <ul>
                                <li style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text"><strong>To enable user-to-user
                                                        communications. </strong>We may process your information if you
                                                    choose to use any of our offerings that allow for communication with
                                                    another user.<span style="color: rgb(89, 89, 89);"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                                data-custom-class="body_text"><span
                                                                    style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="statement-end-if-in-editor">
                                                                                </bdt>
                                                                            </span></span></span></span></span></span></span></span></span></span></span>
                                </li>
                            </ul>
                            <p style="font-size: 15px; line-height: 1.5;">
                                <bdt class="block-component"><span style="font-size: 15px;"></bdt>
                                </span></span></span></span></span></span></span></span></span></span></span></li>
                                </ul>
                            <p style="font-size: 15px; line-height: 1.5;">
                                <bdt class="block-component"></bdt>
                            </p>
                            <ul>
                                <li style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                            style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text"><strong>To request feedback.
                                                    </strong>We may process your information when necessary to request
                                                    feedback and to contact you about your use of our Services.<span
                                                        style="color: rgb(89, 89, 89);"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                                data-custom-class="body_text"><span
                                                                    style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="statement-end-if-in-editor">
                                                                                </bdt>
                                                                            </span></span></span></span></span></span></span></span></span></span></span>
                                </li>
                            </ul>
                            <p style="font-size: 15px; line-height: 1.5;">
                                <bdt class="block-component"></bdt>
                                </span></span></span></span></span></span></span></span></span></span></span></li>
                                </ul>
                            <div style="line-height: 1.5;">
                                <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                            </div>
                            <ul>
                                <li style="line-height: 1.5;"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><strong>To send you marketing and
                                                    promotional communications. </strong>We may process the personal
                                                information you send to us for our marketing purposes, if this is in
                                                accordance with your marketing preferences. You can opt out of our
                                                marketing emails at any time. For more information, see <bdt
                                                    class="block-component"></bdt>"<bdt
                                                    class="statement-end-if-in-editor"></bdt></span></span></span><a
                                        data-custom-class="link" href="#privacyrights"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text">WHAT ARE YOUR PRIVACY
                                                    RIGHTS?</span></span></span></a><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">
                                                <bdt class="block-component"></bdt>"<bdt
                                                    class="statement-end-if-in-editor"></bdt> below).<span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                            data-custom-class="body_text"><span
                                                                style="font-size: 15px;"><span
                                                                    style="color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                    data-custom-class="body_text">
                                                                                    <bdt
                                                                                        class="statement-end-if-in-editor">
                                                                                    </bdt>
                                                                                </span></span></span></span></span></span></span></span></span>
                                            </span></span></span></li>
                            </ul>
                            <div style="line-height: 1.5;">
                                <bdt class="block-component"><span style="font-size: 15px;"></bdt></span></span></span>
                                </li>
                                </ul>
                                <div style="line-height: 1.5;">
                                    <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                                </div>
                                <ul>
                                    <li style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    data-custom-class="body_text"><strong>To deliver targeted
                                                        advertising to you. </strong>We may process your information to
                                                    develop and display <bdt class="block-component"></bdt>personalized
                                                    <bdt class="statement-end-if-in-editor"></bdt> content and
                                                    advertising tailored to your interests, location, and more.<bdt
                                                        class="block-component"></bdt></span><span
                                                    data-custom-class="body_text">
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                </span></span></span></li>
                                </ul>
                                <div style="line-height: 1.5;"><span style="font-size: 15px;">
                                        <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                                    </span></li>
                                    </ul>
                                    <div style="line-height: 1.5;">
                                        <bdt class="block-component"><span style="font-size: 15px;"><span
                                                    data-custom-class="body_text"></bdt></span></span></li>
                                        </ul>
                                        <div style="line-height: 1.5;">
                                            <bdt class="block-component"><span style="font-size: 15px;"><span
                                                        data-custom-class="body_text"></span></span></bdt>
                                        </div>
                                        <ul>
                                            <li style="line-height: 1.5;"><span data-custom-class="body_text"><span
                                                        style="font-size: 15px;"><strong>To protect our
                                                            Services.</strong> We may process your information as part
                                                        of our efforts to keep our Services safe and secure, including
                                                        fraud monitoring and prevention.</span></span>
                                                <bdt class="statement-end-if-in-editor"><span
                                                        style="font-size: 15px;"><span
                                                            data-custom-class="body_text"></span></span></bdt>
                                            </li>
                                        </ul>
                                        <div style="line-height: 1.5;">
                                            <bdt class="block-component"><span style="font-size: 15px;"><span
                                                        data-custom-class="body_text"></span></span></bdt>
                                            </li>
                                            </ul>
                                            <div style="line-height: 1.5;">
                                                <bdt class="block-component"><span style="font-size: 15px;"><span
                                                            data-custom-class="body_text"></span></span></bdt>
                                            </div>
                                            <ul>
                                                <li style="line-height: 1.5;"><span data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><strong>To administer prize draws
                                                                and competitions.</strong> We may process your
                                                            information to administer prize draws and
                                                            competitions.</span></span>
                                                    <bdt class="statement-end-if-in-editor"><span
                                                            style="font-size: 15px;"><span
                                                                data-custom-class="body_text"></span></span></bdt>
                                                </li>
                                            </ul>
                                            <div style="line-height: 1.5;">
                                                <bdt class="block-component"><span style="font-size: 15px;"><span
                                                            data-custom-class="body_text"></span></span></bdt>
                                            </div>
                                            <ul>
                                                <li style="line-height: 1.5;"><span data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><strong>To evaluate and improve our
                                                                Services, products, marketing, and your
                                                                experience.</strong> We may process your information
                                                            when we believe it is necessary to identify usage trends,
                                                            determine the effectiveness of our promotional campaigns,
                                                            and to evaluate and improve our Services, products,
                                                            marketing, and your experience.</span></span>
                                                    <bdt class="statement-end-if-in-editor"><span
                                                            style="font-size: 15px;"><span
                                                                data-custom-class="body_text"></span></span></bdt>
                                                </li>
                                            </ul>
                                            <div style="line-height: 1.5;">
                                                <bdt class="block-component"><span style="font-size: 15px;"><span
                                                            data-custom-class="body_text"></span></span></bdt>
                                            </div>
                                            <ul>
                                                <li style="line-height: 1.5;"><span data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><strong>To identify usage
                                                                trends.</strong> We may process information about how
                                                            you use our Services to better understand how they are being
                                                            used so we can improve them.</span></span>
                                                    <bdt class="statement-end-if-in-editor"><span
                                                            style="font-size: 15px;"><span
                                                                data-custom-class="body_text"></span></span></bdt>
                                                </li>
                                            </ul>
                                            <div style="line-height: 1.5;">
                                                <bdt class="block-component"><span style="font-size: 15px;"><span
                                                            data-custom-class="body_text"></span></span></bdt>
                                                </li>
                                                </ul>
                                                <div style="line-height: 1.5;">
                                                    <bdt class="block-component"><span style="font-size: 15px;"><span
                                                                data-custom-class="body_text"></span></span></bdt>
                                                </div>
                                                <ul>
                                                    <li style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                                                data-custom-class="body_text"><strong>To determine the
                                                                    effectiveness of our marketing and promotional
                                                                    campaigns.</strong> We may process your information
                                                                to better understand how to provide marketing and
                                                                promotional campaigns that are most relevant to you.<bdt
                                                                    class="statement-end-if-in-editor"></bdt>
                                                                </span></span></li>
                                                </ul>
                                                <div style="line-height: 1.5;">
                                                    <bdt class="block-component"><span style="font-size: 15px;"><span
                                                                data-custom-class="body_text"></bdt></span></span></li>
                                                    </ul>
                                                    <div style="line-height: 1.5;">
                                                        <bdt class="block-component"><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text"></span></span></bdt>
                                                    </div>
                                                    <ul>
                                                        <li style="line-height: 1.5;"><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text"><strong>To comply with
                                                                        our legal obligations.</strong> We may process
                                                                    your information to comply with our legal
                                                                    obligations, respond to legal requests, and
                                                                    exercise, establish, or defend our legal rights.<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    </span></span></li>
                                                    </ul>
                                                    <div style="line-height: 1.5;">
                                                        <bdt class="block-component"><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text"></span></span></bdt>
                                                        </li>
                                                        </ul>
                                                        <div style="line-height: 1.5;">
                                                            <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt>
                                                            <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></bdt>
                                                            </span></span>
                                                            <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt>
                                                            <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt>
                                                        </div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div id="legalbases" style="line-height: 1.5;"><strong><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="heading_1">3. WHAT LEGAL
                                                                        BASES DO WE RELY ON TO PROCESS YOUR
                                                                        INFORMATION?</span></span></strong></div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div style="line-height: 1.5;"><em><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><strong>In Short:
                                                                        </strong>We only process your personal
                                                                        information when we believe it is necessary and
                                                                        we have a valid legal reason (i.e.<bdt
                                                                            class="block-component"></bdt>,<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        legal basis) to do so under applicable law, like
                                                                        with your consent, to comply with laws, to
                                                                        provide you with services to enter into or <bdt
                                                                            class="block-component"></bdt>fulfill<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        our contractual obligations, to protect your
                                                                        rights, or to <bdt class="block-component">
                                                                        </bdt>fulfill<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        our legitimate business
                                                                        interests.</span></span></em><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component">
                                                                </span></span></bdt>
                                                            <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt><span data-custom-class="body_text"><span
                                                                    style="font-size: 15px;">
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span>
                                                        </div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div style="line-height: 1.5;"><span
                                                                data-custom-class="body_text"><span
                                                                    style="font-size: 15px;">We may process your
                                                                    information if you have given us specific permission
                                                                    (i.e.<bdt class="block-component"></bdt>,<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    express consent) to use your personal information
                                                                    for a specific purpose, or in situations where your
                                                                    permission can be inferred (i.e.<bdt
                                                                        class="block-component"></bdt>,<bdt
                                                                        class="statement-end-if-in-editor"></bdt>
                                                                    implied consent). You can withdraw your consent at
                                                                    any time. Click </span></span><a
                                                                data-custom-class="link" href="#withdrawconsent"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">here</span></span></a><span
                                                                data-custom-class="body_text"><span
                                                                    style="font-size: 15px;"> to learn
                                                                    more.</span></span></div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div style="line-height: 1.5;"><span
                                                                data-custom-class="body_text"><span
                                                                    style="font-size: 15px;">In some exceptional cases,
                                                                    we may be legally permitted under applicable law to
                                                                    process your information without your consent,
                                                                    including, for example:</span></span></div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">If collection is
                                                                        clearly in the interests of an individual and
                                                                        consent cannot be obtained in a timely
                                                                        way</span></span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;"><span
                                                                data-custom-class="body_text"><span
                                                                    style="font-size: 15px;">
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span></div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">For investigations and
                                                                        fraud detection and prevention<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        </span></span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;">
                                                            <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">For business
                                                                        transactions provided certain conditions are
                                                                        met</span></span>
                                                                <bdt class="statement-end-if-in-editor"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"></span></span>
                                                                </bdt>
                                                            </li>
                                                        </ul>
                                                        <div style="line-height: 1.5;">
                                                            <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">If it is contained in a
                                                                        witness statement and the collection is
                                                                        necessary to assess, process, or settle an
                                                                        insurance claim</span></span>
                                                                <bdt class="statement-end-if-in-editor"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"></span></span>
                                                                </bdt>
                                                            </li>
                                                        </ul>
                                                        <div style="line-height: 1.5;">
                                                            <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">For identifying
                                                                        injured, ill, or deceased persons and
                                                                        communicating with next of kin</span></span>
                                                                <bdt class="statement-end-if-in-editor"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"></span></span>
                                                                </bdt>
                                                            </li>
                                                        </ul>
                                                        <div style="line-height: 1.5;"><span
                                                                data-custom-class="body_text"><span
                                                                    style="font-size: 15px;">
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span></div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">If we have reasonable
                                                                        grounds to believe an individual has been, is,
                                                                        or may be victim of financial abuse<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        </span></span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;"><span
                                                                data-custom-class="body_text"><span
                                                                    style="font-size: 15px;">
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span></div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">If it is reasonable to
                                                                        expect collection and use with consent would
                                                                        compromise the availability or the accuracy of
                                                                        the information and the collection is reasonable
                                                                        for purposes related to investigating a breach
                                                                        of an agreement or a contravention of the laws
                                                                        of Canada or a province<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        </span></span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;"><span
                                                                data-custom-class="body_text"><span
                                                                    style="font-size: 15px;">
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span></div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">If disclosure is
                                                                        required to comply with a subpoena, warrant,
                                                                        court order, or rules of the court relating to
                                                                        the production of records<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        </span></span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;">
                                                            <bdt class="block-component"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text">If it was produced
                                                                        by an individual in the course of their
                                                                        employment, business, or profession and the
                                                                        collection is consistent with the purposes for
                                                                        which the information was produced<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        </span></span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;"><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span></div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text">If the collection
                                                                        is solely for journalistic, artistic, or
                                                                        literary purposes<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        </span></span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;"><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span></div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text">If the information
                                                                        is publicly available and is specified by the
                                                                        regulations</span>
                                                                    <bdt class="statement-end-if-in-editor"><span
                                                                            data-custom-class="body_text"></span></bdt>
                                                                </span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;">
                                                            <bdt class="statement-end-if-in-editor"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt>
                                                            <bdt class="statement-end-if-in-editor"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"></span></span>
                                                            </bdt>
                                                        </div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div id="whoshare" style="line-height: 1.5;"><span
                                                                style="color: rgb(127, 127, 127);"><span
                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                id="control"
                                                                                style="color: rgb(0, 0, 0);"><strong><span
                                                                                        data-custom-class="heading_1">4.
                                                                                        WHEN AND WITH WHOM DO WE SHARE
                                                                                        YOUR PERSONAL
                                                                                        INFORMATION?</span></strong></span></span></span></span></span>
                                                        </div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div style="line-height: 1.5;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><strong><em>In
                                                                                Short:</em></strong><em> We may share
                                                                            information in specific situations described
                                                                            in this section and/or with the following
                                                                            <bdt class="block-component"></bdt>third
                                                                            parties.</em></span></span></span></div>
                                                        <div style="line-height: 1.5;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">
                                                                        <bdt class="block-component"></bdt>
                                                                    </span></span></span></div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div style="line-height: 1.5;"><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text"><strong>Vendors,
                                                                        Consultants, and Other Third-Party Service
                                                                        Providers.</strong> We may share your data with
                                                                    third-party vendors, service providers, contractors,
                                                                    or agents (<bdt class="block-component"></bdt>
                                                                    "<strong>third parties</strong>"<bdt
                                                                        class="statement-end-if-in-editor"></bdt>) who
                                                                    perform services for us or on our behalf and require
                                                                    access to such information to do that work. <bdt
                                                                        class="block-component"></bdt>We have contracts
                                                                    in place with our third parties, which are designed
                                                                    to help safeguard your personal information. This
                                                                    means that they cannot do anything with your
                                                                    personal information unless we have instructed them
                                                                    to do it. They will also not share your personal
                                                                    information with any <bdt class="block-component">
                                                                    </bdt>organization<bdt
                                                                        class="statement-end-if-in-editor"></bdt> apart
                                                                    from us. They also commit to protect the data they
                                                                    hold on our behalf and to retain it for the period
                                                                    we instruct. <bdt
                                                                        class="statement-end-if-in-editor"></bdt>The
                                                                    <bdt class="block-component"></bdt>third parties we
                                                                    may share personal information with are as
                                                                    follows:</span></span><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text">
                                                                        <bdt class="block-component"></bdt>
                                                                    </span></span></span></span></span></span></span></span></span></span></span></span>
                                                            </bdt></span></span></span></bdt>
                                                            </span></span></span></span></span></span><span
                                                                data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            data-custom-class="body_text"><strong>Allow
                                                                                Users to Connect to Their Third-Party
                                                                                Accounts</strong></span></span></span>
                                                            </li>
                                                        </ul>
                                                        <div style="margin-left: 40px;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);">
                                                                                <bdt class="forloop-component"><span
                                                                                        data-custom-class="body_text"></span>
                                                                                </bdt><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                            <bdt class="question">Google
                                                                                                account</bdt>
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                            </span>
                                                                            <bdt class="forloop-component"></bdt>
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            data-custom-class="body_text"><strong>Cloud
                                                                                Computing
                                                                                Services</strong></span></span></span>
                                                            </li>
                                                        </ul>
                                                        <div style="margin-left: 40px;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);">
                                                                                <bdt class="forloop-component"><span
                                                                                        data-custom-class="body_text"></span>
                                                                                </bdt><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                            <bdt class="question">Google
                                                                                                Cloud Platform</bdt>
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                            </span>
                                                                            <bdt class="forloop-component"></bdt>
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            data-custom-class="body_text"><strong>Communicate
                                                                                and Chat with
                                                                                Users</strong></span></span></span></li>
                                                        </ul>
                                                        <div style="margin-left: 40px;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);">
                                                                                <bdt class="forloop-component"><span
                                                                                        data-custom-class="body_text"></span>
                                                                                </bdt><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                            <bdt class="question">
                                                                                                Firebase Cloud Messaging
                                                                                            </bdt>
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                            </span>
                                                                            <bdt class="forloop-component"></bdt>
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            data-custom-class="body_text"><strong>Functionality
                                                                                and Infrastructure <bdt
                                                                                    class="block-component"></bdt>
                                                                                Optimization<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt></strong></span></span></span>
                                                            </li>
                                                        </ul>
                                                        <div style="margin-left: 40px;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);">
                                                                                <bdt class="forloop-component"><span
                                                                                        data-custom-class="body_text"></span>
                                                                                </bdt><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                            <bdt class="question">Google
                                                                                                Cloud Storage </bdt>
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                            </span>
                                                                            <bdt class="forloop-component">
                                                                        </span></bdt><span
                                                                            data-custom-class="body_text"><span
                                                                                style="font-size: 15px;"><span
                                                                                    style="color: rgb(89, 89, 89);">
                                                                                    <bdt class="block-component"></bdt>
                                                                                    ,<span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt class="question">Cloud
                                                                                                Firestore</bdt>
                                                                                            <bdt class="else-block">
                                                                                            </bdt>
                                                                                        </span></span>
                                                                                </span></span>
                                                                            <bdt class="forloop-component">
                                                                        </span></bdt><span
                                                                            data-custom-class="body_text"><span
                                                                                style="font-size: 15px;"><span
                                                                                    style="color: rgb(89, 89, 89);">
                                                                                    <bdt class="block-component"></bdt>
                                                                                    ,<span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt class="question">
                                                                                                Firebase Realtime
                                                                                                Database </bdt>
                                                                                            <bdt class="else-block">
                                                                                            </bdt>
                                                                                        </span></span>
                                                                                </span></span>
                                                                            <bdt class="forloop-component">
                                                                        </span></bdt><span
                                                                            data-custom-class="body_text"><span
                                                                                style="font-size: 15px;"><span
                                                                                    style="color: rgb(89, 89, 89);">
                                                                                    <bdt class="block-component"></bdt>
                                                                                </span></span> and <bdt
                                                                                class="question">Termly.io</bdt><span
                                                                                style="font-size: 15px;"><span
                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="statement-end-if-in-editor">
                                                                                            </bdt>
                                                                                        </span></span></span></span>
                                                                            <bdt class="forloop-component"></bdt>
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            data-custom-class="body_text"><strong>Invoice
                                                                                and
                                                                                Billing</strong></span></span></span>
                                                            </li>
                                                        </ul>
                                                        <div style="margin-left: 40px;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);">
                                                                                <bdt class="forloop-component"><span
                                                                                        data-custom-class="body_text"></span>
                                                                                </bdt><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                            <bdt class="question">Stripe
                                                                                            </bdt>
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                            </span>
                                                                            <bdt class="forloop-component"></bdt>
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            data-custom-class="body_text"><strong>User
                                                                                Account Registration and
                                                                                Authentication</strong></span></span></span>
                                                            </li>
                                                        </ul>
                                                        <div style="margin-left: 40px;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);">
                                                                                <bdt class="forloop-component"><span
                                                                                        data-custom-class="body_text"></span>
                                                                                </bdt><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                            <bdt class="question">Google
                                                                                                Sign-In</bdt>
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                            </span>
                                                                            <bdt class="forloop-component"></bdt>
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            data-custom-class="body_text"><strong>Web
                                                                                and Mobile
                                                                                Analytics</strong></span></span></span>
                                                            </li>
                                                        </ul>
                                                        <div style="margin-left: 40px;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);">
                                                                                <bdt class="forloop-component"><span
                                                                                        data-custom-class="body_text"></span>
                                                                                </bdt><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                            <bdt class="question">Google
                                                                                                Analytics for Firebase
                                                                                            </bdt>
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                            </span>
                                                                            <bdt class="forloop-component"></bdt>
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            data-custom-class="body_text"><strong>Website
                                                                                Performance
                                                                                Monitoring</strong></span></span></span>
                                                            </li>
                                                        </ul>
                                                        <div style="margin-left: 40px;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);">
                                                                                <bdt class="forloop-component"><span
                                                                                        data-custom-class="body_text"></span>
                                                                                </bdt><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                            <bdt class="question">
                                                                                                Firebase Crash Reporting
                                                                                            </bdt>
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                            </span>
                                                                            <bdt class="forloop-component"></bdt>
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span>
                                                            <bdt class="block-component"></bdt>
                                                        </div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            data-custom-class="body_text"><strong>Website
                                                                                Testing</strong></span></span></span>
                                                            </li>
                                                        </ul>
                                                        <div style="margin-left: 40px;"><span
                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);">
                                                                                <bdt class="forloop-component"><span
                                                                                        data-custom-class="body_text"></span>
                                                                                </bdt><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                            <bdt class="question">
                                                                                                Firebase Remote Config
                                                                                            </bdt>
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                            </span>
                                                                            <bdt class="forloop-component">
                                                                        </span></bdt><span
                                                                            data-custom-class="body_text"><span
                                                                                style="font-size: 15px;"><span
                                                                                    style="color: rgb(89, 89, 89);">
                                                                                    <bdt class="block-component"></bdt>
                                                                                </span></span> and <bdt
                                                                                class="question">Google Play Console
                                                                            </bdt><span style="font-size: 15px;"><span
                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                        style="font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89);">
                                                                                            <bdt
                                                                                                class="statement-end-if-in-editor">
                                                                                            </bdt>
                                                                                        </span></span></span></span>
                                                                            <bdt class="forloop-component"></bdt>
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span></span></span></span></span></span></span><span
                                                                data-custom-class="body_text"></span><span
                                                                data-custom-class="body_text"></span><span
                                                                data-custom-class="body_text"></span><span
                                                                data-custom-class="body_text"></span><span
                                                                data-custom-class="body_text"></span><span
                                                                data-custom-class="body_text"></span></div>
                                                        <div style="line-height: 1.5;"><br></div>
                                                        <div style="line-height: 1.5;"><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text">We <bdt
                                                                        class="block-component"></bdt>also <bdt
                                                                        class="statement-end-if-in-editor"></bdt>may
                                                                    need to share your personal information in the
                                                                    following situations:</span></span></div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><strong>Business
                                                                            Transfers.</strong> We may share or transfer
                                                                        your information in connection with, or during
                                                                        negotiations of, any merger, sale of company
                                                                        assets, financing, or acquisition of all or a
                                                                        portion of our business to another
                                                                        company.</span></span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;"><span
                                                                style="font-size: 15px;"><span
                                                                    data-custom-class="body_text">
                                                                    <bdt class="block-component"></bdt>
                                                                </span></span></div>
                                                        <ul>
                                                            <li style="line-height: 1.5;"><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text"><strong>When we
                                                                            use Google Maps Platform APIs.</strong> We
                                                                        may share your information with certain Google
                                                                        Maps Platform APIs (e.g.<bdt
                                                                            class="block-component"></bdt>,<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                        Google Maps API, Places API). To find out more
                                                                        about Google’s Privacy Policy, please refer to
                                                                        this </span></span><a data-custom-class="link"
                                                                    href="https://policies.google.com/privacy"
                                                                    rel="noopener noreferrer" target="_blank"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text">link</span></span></a><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text">.<bdt
                                                                            class="block-component"></bdt> We use
                                                                        certain Google Maps Platform APIs to retrieve
                                                                        certain information when you make
                                                                        location-specific requests. This includes:<bdt
                                                                            class="forloop-component"></bdt>
                                                                        <bdt class="question">loaction data</bdt>;<bdt
                                                                            class="forloop-component"></bdt> and other
                                                                        similar information. A full list of what we use
                                                                        information for can be found in this section and
                                                                        in the previous section titled <bdt
                                                                            class="block-component"></bdt>"<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                    </span></span><a data-custom-class="link"
                                                                    href="#infouse"><span style="font-size: 15px;"><span
                                                                            data-custom-class="body_text">HOW DO WE
                                                                            PROCESS YOUR
                                                                            INFORMATION?</span></span></a><span
                                                                    style="font-size: 15px;"><span
                                                                        data-custom-class="body_text">
                                                                        <bdt class="block-component"></bdt>"<bdt
                                                                            class="statement-end-if-in-editor">.</bdt>
                                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                                        <bdt class="block-component"></bdt>
                                                                        <bdt class="block-component"></bdt> The Google
                                                                        Maps Platform APIs that we use store and access
                                                                        cookies and other information on your devices.
                                                                        If you are a user currently in the European
                                                                        Economic Area (EU countries, Iceland,
                                                                        Liechtenstein, and Norway) or the United
                                                                        Kingdom, please take a look at our Cookie Notice
                                                                        <bdt class="block-component"></bdt>.<bdt
                                                                            class="statement-end-if-in-editor"></bdt>
                                                                    </span></span></li>
                                                        </ul>
                                                        <div style="line-height: 1.5;"><span style="font-size: 15px;">
                                                                <bdt class="block-component"><span
                                                                        data-custom-class="body_text"></span></bdt>
                                                            </span></li>
                                                            </ul>
                                                            <div style="line-height: 1.5;">
                                                                <bdt class="block-component"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"></span></span>
                                                                </bdt>
                                                            </div>
                                                            <ul>
                                                                <li style="line-height: 1.5;"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;"><strong>Business
                                                                                Partners.</strong> We may share your
                                                                            information with our business partners to
                                                                            offer you certain products, services, or
                                                                            promotions.</span></span>
                                                                    <bdt class="statement-end-if-in-editor"><span
                                                                            style="font-size: 15px;"><span
                                                                                data-custom-class="body_text"></span></span>
                                                                    </bdt>
                                                                </li>
                                                            </ul>
                                                            <div style="line-height: 1.5;">
                                                                <bdt class="block-component"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"></span></span>
                                                                </bdt>
                                                            </div>
                                                            <ul>
                                                                <li style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"><strong>Other
                                                                                Users.</strong> When you share personal
                                                                            information<bdt class="block-component">
                                                                            </bdt> (for example, by posting comments,
                                                                            contributions, or other content to the
                                                                            Services)<bdt
                                                                                class="statement-end-if-in-editor">
                                                                            </bdt> or otherwise interact with public
                                                                            areas of the Services, such personal
                                                                            information may be viewed by all users and
                                                                            may be publicly made available outside the
                                                                            Services in perpetuity.<bdt
                                                                                class="block-component"></bdt> If you
                                                                            interact with other users of our Services
                                                                            and register for our Services through a
                                                                            social network (such as Facebook), your
                                                                            contacts on the social network will see your
                                                                            name, profile photo, and descriptions of
                                                                            your activity.<bdt
                                                                                class="statement-end-if-in-editor">
                                                                            </bdt> Similarly, other users will be able
                                                                            to view descriptions of your activity,
                                                                            communicate with you within our Services,
                                                                            and view your profile.<bdt
                                                                                class="statement-end-if-in-editor">
                                                                            </bdt></span></span></li>
                                                            </ul>
                                                            <div style="line-height: 1.5;">
                                                                <bdt class="block-component"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"></span></span>
                                                                </bdt><span data-custom-class="body_text"><span
                                                                        style="font-size: 15px;">
                                                                        <bdt class="block-component"></bdt>
                                                                    </span></span>
                                                                <bdt class="statement-end-if-in-editor"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"></span></span>
                                                                </bdt>
                                                                </li>
                                                                </ul>
                                                                <div style="line-height: 1.5;">
                                                                    <bdt class="block-component"><span
                                                                            style="font-size: 15px;"><span
                                                                                data-custom-class="body_text"></span></span>
                                                                    </bdt><span
                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                            style="font-size: 15px;"><span
                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89);">
                                                                                        <bdt class="block-component">
                                                                                            <span
                                                                                                data-custom-class="heading_1"></span>
                                                                                        </bdt>
                                                                                    </span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="cookies" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">5.
                                                                                                DO WE USE COOKIES AND
                                                                                                OTHER TRACKING
                                                                                                TECHNOLOGIES?</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><strong><em>In
                                                                                        Short:</em></strong><em> We may
                                                                                    use cookies and other tracking
                                                                                    technologies to collect and store
                                                                                    your
                                                                                    information.</em></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">We may use
                                                                                cookies and similar tracking
                                                                                technologies (like web beacons and
                                                                                pixels) to access or store information.
                                                                                Specific information about how we use
                                                                                such technologies and how you can refuse
                                                                                certain cookies is set out in our Cookie
                                                                                Notice<span
                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                        data-custom-class="body_text">
                                                                                        <bdt class="block-component">
                                                                                        </bdt>.
                                                                                    </span><span
                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                            style="font-size: 15px;"><span
                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                    style="font-size: 15px;"><span
                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                            data-custom-class="body_text">
                                                                                                            <bdt
                                                                                                                class="statement-end-if-in-editor">
                                                                                                            </bdt>
                                                                                                        </span></span></span></span></span></span></span></span><span
                                                                                data-custom-class="body_text"><span
                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                style="font-size: 15px;"><span
                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                        style="font-size: 15px;"><span
                                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                                data-custom-class="body_text">
                                                                                                                <bdt
                                                                                                                    class="block-component">
                                                                                                                </bdt>
                                                                                                            </span></span></span></span></span></span></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="sociallogins" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">6.
                                                                                                HOW DO WE HANDLE YOUR
                                                                                                SOCIAL
                                                                                                LOGINS?</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><strong><em>In
                                                                                        Short: </em></strong><em>If you
                                                                                    choose to register or log in to our
                                                                                    services using a social media
                                                                                    account, we may have access to
                                                                                    certain information about
                                                                                    you.</em></span></span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">Our
                                                                                Services offer you the ability to
                                                                                register and log in using your
                                                                                third-party social media account details
                                                                                (like your Facebook or Twitter logins).
                                                                                Where you choose to do this, we will
                                                                                receive certain profile information
                                                                                about you from your social media
                                                                                provider. The profile information we
                                                                                receive may vary depending on the social
                                                                                media provider concerned, but will often
                                                                                include your name, email address,
                                                                                friends list, and profile picture, as
                                                                                well as other information you choose to
                                                                                make public on such a social media
                                                                                platform. <span
                                                                                    style="font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            data-custom-class="body_text">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span></span></span></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">We will
                                                                                use the information we receive only for
                                                                                the purposes that are described in this
                                                                                privacy notice or that are otherwise
                                                                                made clear to you on the relevant
                                                                                Services. Please note that we do not
                                                                                control, and are not responsible for,
                                                                                other uses of your personal information
                                                                                by your third-party social media
                                                                                provider. We recommend that you review
                                                                                their privacy notice to understand how
                                                                                they collect, use, and share your
                                                                                personal information, and how you can
                                                                                set your privacy preferences on their
                                                                                sites and apps.<span
                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                                    style="font-size: 15px;"><span
                                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                                            style="font-size: 15px;"><span
                                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                                    data-custom-class="body_text">
                                                                                                                    <bdt
                                                                                                                        class="statement-end-if-in-editor">
                                                                                                                    </bdt>
                                                                                                                </span>
                                                                                                                <bdt
                                                                                                                    class="block-component">
                                                                                                                    <span
                                                                                                                        data-custom-class="body_text">
                                                                                                                        <bdt
                                                                                                                            class="block-component">
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                            </span></span></span></span></span></span></span></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="inforetain" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">7.
                                                                                                HOW LONG DO WE KEEP YOUR
                                                                                                INFORMATION?</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><strong><em>In
                                                                                        Short: </em></strong><em>We keep
                                                                                    your information for as long as
                                                                                    necessary to <bdt
                                                                                        class="block-component"></bdt>
                                                                                    fulfill<bdt
                                                                                        class="statement-end-if-in-editor">
                                                                                    </bdt> the purposes outlined in this
                                                                                    privacy notice unless otherwise
                                                                                    required by
                                                                                    law.</em></span></span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">We will
                                                                                only keep your personal information for
                                                                                as long as it is necessary for the
                                                                                purposes set out in this privacy notice,
                                                                                unless a longer retention period is
                                                                                required or permitted by law (such as
                                                                                tax, accounting, or other legal
                                                                                requirements).<bdt
                                                                                    class="block-component"></bdt> No
                                                                                purpose in this notice will require us
                                                                                keeping your personal information for
                                                                                longer than <span
                                                                                    style="font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            data-custom-class="body_text">
                                                                                            <bdt
                                                                                                class="block-component">
                                                                                            </bdt>
                                                                                        </span></span></span>
                                                                                <bdt class="block-component"></bdt>the
                                                                                period of time in which users have an
                                                                                account with us<bdt
                                                                                    class="block-component"></bdt><span
                                                                                    style="font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            data-custom-class="body_text">
                                                                                            <bdt class="else-block">
                                                                                            </bdt>
                                                                                        </span></span></span>.
                                                                            </span></span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">When we
                                                                                have no ongoing legitimate business need
                                                                                to process your personal information, we
                                                                                will either delete or <bdt
                                                                                    class="block-component"></bdt>
                                                                                anonymize<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> such information, or, if this is
                                                                                not possible (for example, because your
                                                                                personal information has been stored in
                                                                                backup archives), then we will securely
                                                                                store your personal information and
                                                                                isolate it from any further processing
                                                                                until deletion is possible.<span
                                                                                    style="color: rgb(89, 89, 89);">
                                                                                    <bdt class="block-component"></bdt>
                                                                                </span></span></span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="infosafe" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">8.
                                                                                                HOW DO WE KEEP YOUR
                                                                                                INFORMATION
                                                                                                SAFE?</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><strong><em>In
                                                                                        Short: </em></strong><em>We aim
                                                                                    to protect your personal information
                                                                                    through a system of <bdt
                                                                                        class="block-component"></bdt>
                                                                                    organizational<bdt
                                                                                        class="statement-end-if-in-editor">
                                                                                    </bdt> and technical security
                                                                                    measures.</em></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">We have
                                                                                implemented appropriate and reasonable
                                                                                technical and <bdt
                                                                                    class="block-component"></bdt>
                                                                                organizational<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> security measures designed to
                                                                                protect the security of any personal
                                                                                information we process. However, despite
                                                                                our safeguards and efforts to secure
                                                                                your information, no electronic
                                                                                transmission over the Internet or
                                                                                information storage technology can be
                                                                                guaranteed to be 100% secure, so we
                                                                                cannot promise or guarantee that
                                                                                hackers, cybercriminals, or other <bdt
                                                                                    class="block-component"></bdt>
                                                                                unauthorized<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> third parties will not be able to
                                                                                defeat our security and improperly
                                                                                collect, access, steal, or modify your
                                                                                information. Although we will do our
                                                                                best to protect your personal
                                                                                information, transmission of personal
                                                                                information to and from our Services is
                                                                                at your own risk. You should only access
                                                                                the Services within a secure
                                                                                environment.<span
                                                                                    style="color: rgb(89, 89, 89);">
                                                                                    <bdt
                                                                                        class="statement-end-if-in-editor">
                                                                                    </bdt>
                                                                                </span><span
                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                        data-custom-class="body_text">
                                                                                        <bdt class="block-component">
                                                                                        </bdt>
                                                                                    </span></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="privacyrights" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">9.
                                                                                                WHAT ARE YOUR PRIVACY
                                                                                                RIGHTS?</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><strong><em>In
                                                                                        Short:</em></strong><em> <span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            style="font-size: 15px;"><span
                                                                                                data-custom-class="body_text"><em>
                                                                                                    <bdt
                                                                                                        class="block-component">
                                                                                                    </bdt>
                                                                                                </em></span></span></span>In
                                                                                    some regions, such as <bdt
                                                                                        class="block-component"></bdt>
                                                                                    Canada<bdt
                                                                                        class="statement-end-if-in-editor">
                                                                                    </bdt>, you have rights that allow
                                                                                    you greater access to and control
                                                                                    over your personal information.<span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            style="font-size: 15px;"><span
                                                                                                data-custom-class="body_text"><em>
                                                                                                    <bdt
                                                                                                        class="statement-end-if-in-editor">
                                                                                                    </bdt>
                                                                                                </em></span></span>
                                                                                    </span>You may review, change, or
                                                                                    terminate your account at any
                                                                                    time.</em><span
                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                        style="font-size: 15px;">
                                                                                        <bdt class="block-component">
                                                                                        </bdt>
                                                                                    </span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">In some
                                                                                regions (like <bdt
                                                                                    class="block-component"></bdt>Canada
                                                                                <bdt class="statement-end-if-in-editor">
                                                                                </bdt>), you have certain rights under
                                                                                applicable data protection laws. These
                                                                                may include the right (i) to request
                                                                                access and obtain a copy of your
                                                                                personal information, (ii) to request
                                                                                rectification or erasure; (iii) to
                                                                                restrict the processing of your personal
                                                                                information; and (iv) if applicable, to
                                                                                data portability. In certain
                                                                                circumstances, you may also have the
                                                                                right to object to the processing of
                                                                                your personal information. You can make
                                                                                such a request by contacting us by using
                                                                                the contact details provided in the
                                                                                section <bdt class="block-component">
                                                                                </bdt>"<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt></span></span></span><a
                                                                        data-custom-class="link" href="#contact"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    data-custom-class="body_text">HOW
                                                                                    CAN YOU CONTACT US ABOUT THIS
                                                                                    NOTICE?</span></span></span></a><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="block-component"></bdt>"<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> below.
                                                                            </span></span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">We will
                                                                                consider and act upon any request in
                                                                                accordance with applicable data
                                                                                protection laws.</span><span
                                                                                data-custom-class="body_text"><span
                                                                                    style="font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            data-custom-class="body_text"><span
                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                    style="font-size: 15px;">
                                                                                                    <bdt
                                                                                                        class="statement-end-if-in-editor">
                                                                                                    </bdt>
                                                                                                </span></span></span></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);">
                                                                    </span></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">If you are
                                                                                located in the EEA or UK and you believe
                                                                                we are unlawfully processing your
                                                                                personal information, you also have the
                                                                                right to complain to your local data
                                                                                protection supervisory authority. You
                                                                                can find their contact details here:
                                                                                <span style="font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            data-custom-class="body_text"><span
                                                                                                style="color: rgb(48, 48, 241);"><span
                                                                                                    data-custom-class="body_text"><a
                                                                                                        data-custom-class="link"
                                                                                                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                                        rel="noopener noreferrer"
                                                                                                        target="_blank"><span
                                                                                                            style="font-size: 15px;">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a></span></span></span></span></span>.</span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">If you are
                                                                                located in Switzerland, the contact
                                                                                details for the data protection
                                                                                authorities are available here: <span
                                                                                    style="font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            data-custom-class="body_text"><span
                                                                                                style="color: rgb(48, 48, 241);"><span
                                                                                                    data-custom-class="body_text"><span
                                                                                                        style="font-size: 15px;"><a
                                                                                                            data-custom-class="link"
                                                                                                            href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                                            rel="noopener noreferrer"
                                                                                                            target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span></span></span></span></span></span>.</span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="withdrawconsent" style="line-height: 1.5;">
                                                                    <span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><strong><u>Withdrawing
                                                                                        your consent:</u></strong> If we
                                                                                are relying on your consent to process
                                                                                your personal information,<bdt
                                                                                    class="block-component"></bdt> which
                                                                                may be express and/or implied consent
                                                                                depending on the applicable law,<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> you have the right to withdraw
                                                                                your consent at any time. You can
                                                                                withdraw your consent at any time by
                                                                                contacting us by using the contact
                                                                                details provided in the section <bdt
                                                                                    class="block-component"></bdt>"<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt></span></span></span><a
                                                                        data-custom-class="link" href="#contact"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    data-custom-class="body_text">HOW
                                                                                    CAN YOU CONTACT US ABOUT THIS
                                                                                    NOTICE?</span></span></span></a><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="block-component"></bdt>"<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> below<bdt
                                                                                    class="block-component"></bdt>.
                                                                            </span></span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text">However,
                                                                            please note that this will not affect the
                                                                            lawfulness of the processing before its
                                                                            withdrawal nor,<bdt class="block-component">
                                                                            </bdt> when applicable law allows,<bdt
                                                                                class="statement-end-if-in-editor">
                                                                            </bdt> will it affect the processing of your
                                                                            personal information conducted in reliance
                                                                            on lawful processing grounds other than
                                                                            consent.<bdt class="block-component"></bdt>
                                                                            </span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"><strong><u>Opting
                                                                                    out of marketing and promotional
                                                                                    communications:</u></strong><strong><u>
                                                                                </u></strong>You can unsubscribe from
                                                                            our marketing and promotional communications
                                                                            at any time by<bdt class="block-component">
                                                                            </bdt> clicking on the unsubscribe link in
                                                                            the emails that we send,<bdt
                                                                                class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                            <bdt class="block-component"></bdt>
                                                                            <bdt class="block-component"></bdt> or by
                                                                            contacting us using the details provided in
                                                                            the section <bdt class="block-component">
                                                                            </bdt>"<bdt
                                                                                class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span><a data-custom-class="link"
                                                                        href="#contact"><span
                                                                            style="font-size: 15px;"><span
                                                                                data-custom-class="body_text">HOW CAN
                                                                                YOU CONTACT US ABOUT THIS
                                                                                NOTICE?</span></span></a><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text">
                                                                            <bdt class="block-component"></bdt>"<bdt
                                                                                class="statement-end-if-in-editor">
                                                                            </bdt> below. You will then be removed from
                                                                            the marketing lists. However, we may still
                                                                            communicate with you — for example, to send
                                                                            you service-related messages that are
                                                                            necessary for the administration and use of
                                                                            your account, to respond to service
                                                                            requests, or for other non-marketing
                                                                            purposes.
                                                                        </span><span data-custom-class="body_text">
                                                                            <bdt class="statement-end-if-in-editor">
                                                                            </bdt>
                                                                        </span></span>
                                                                    <bdt class="block-component"><span
                                                                            style="font-size: 15px;"><span
                                                                                data-custom-class="body_text"></span></span>
                                                                    </bdt>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="heading_2"><strong>Account
                                                                                Information</strong></span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;">If you would at any
                                                                            time like to review or change the
                                                                            information in your account or terminate
                                                                            your account, you can:<bdt
                                                                                class="forloop-component"></bdt>
                                                                            </span></span></div>
                                                                <ul>
                                                                    <li style="line-height: 1.5;"><span
                                                                            data-custom-class="body_text"><span
                                                                                style="font-size: 15px;">
                                                                                <bdt class="question">Log in to your
                                                                                    account settings and update your
                                                                                    user account.</bdt>
                                                                            </span></span></li>
                                                                </ul>
                                                                <div style="line-height: 1.5;"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;">
                                                                            <bdt class="forloop-component"></bdt>
                                                                        </span></span></div>
                                                                <ul>
                                                                    <li style="line-height: 1.5;"><span
                                                                            data-custom-class="body_text"><span
                                                                                style="font-size: 15px;">
                                                                                <bdt class="question">Contact us using
                                                                                    the contact information provided.
                                                                                </bdt>
                                                                            </span></span></li>
                                                                </ul>
                                                                <div style="line-height: 1.5;"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;">
                                                                            <bdt class="forloop-component"></bdt>
                                                                        </span></span></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text">Upon your
                                                                            request to terminate your account, we will
                                                                            deactivate or delete your account and
                                                                            information from our active databases.
                                                                            However, we may retain some information in
                                                                            our files to prevent fraud, troubleshoot
                                                                            problems, assist with any investigations,
                                                                            enforce our legal terms and/or comply with
                                                                            applicable legal requirements.</span></span>
                                                                    <bdt class="statement-end-if-in-editor"><span
                                                                            style="font-size: 15px;"><span
                                                                                data-custom-class="body_text"></span></span>
                                                                    </bdt><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><span
                                                                                    style="font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            style="font-size: 15px;"><span
                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                    data-custom-class="body_text"><span
                                                                                                        style="font-size: 15px;"><span
                                                                                                            style="color: rgb(89, 89, 89);">
                                                                                                            <bdt
                                                                                                                class="block-component">
                                                                                                            </bdt>
                                                                                                        </span></span></span></span></span></span></span></span></span></span></span></span>
                                                                    <bdt class="block-component"><span
                                                                            style="font-size: 15px;"><span
                                                                                data-custom-class="body_text"></span></span>
                                                                    </bdt>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        data-custom-class="body_text"><span
                                                                            style="font-size: 15px;">If you have
                                                                            questions or comments about your privacy
                                                                            rights, you may email us at <bdt
                                                                                class="question">support@swiftrecharge.ng
                                                                            </bdt>.</span></span>
                                                                    <bdt class="statement-end-if-in-editor"><span
                                                                            style="font-size: 15px;"><span
                                                                                data-custom-class="body_text"></span></span>
                                                                    </bdt>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="DNT" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">10.
                                                                                                CONTROLS FOR
                                                                                                DO-NOT-TRACK
                                                                                                FEATURES</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">Most web
                                                                                browsers and some mobile operating
                                                                                systems and mobile applications include
                                                                                a Do-Not-Track (<bdt
                                                                                    class="block-component"></bdt>"DNT"
                                                                                <bdt class="statement-end-if-in-editor">
                                                                                </bdt>) feature or setting you can
                                                                                activate to signal your privacy
                                                                                preference not to have data about your
                                                                                online browsing activities monitored and
                                                                                collected. At this stage no uniform
                                                                                technology standard for <bdt
                                                                                    class="block-component"></bdt>
                                                                                recognizing<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> and implementing DNT signals has
                                                                                been <bdt class="block-component"></bdt>
                                                                                finalized<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt>. As such, we do not currently
                                                                                respond to DNT browser signals or any
                                                                                other mechanism that automatically
                                                                                communicates your choice not to be
                                                                                tracked online. If a standard for online
                                                                                tracking is adopted that we must follow
                                                                                in the future, we will inform you about
                                                                                that practice in a revised version of
                                                                                this privacy
                                                                                notice.</span></span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="caresidents" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">11.
                                                                                                DO CALIFORNIA RESIDENTS
                                                                                                HAVE SPECIFIC PRIVACY
                                                                                                RIGHTS?</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><strong><em>In
                                                                                        Short: </em></strong><em>Yes, if
                                                                                    you are a resident of California,
                                                                                    you are granted specific rights
                                                                                    regarding access to your personal
                                                                                    information.</em></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">California
                                                                                Civil Code Section 1798.83, also known
                                                                                as the <bdt class="block-component">
                                                                                </bdt>"Shine The Light"<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> law, permits our users who are
                                                                                California residents to request and
                                                                                obtain from us, once a year and free of
                                                                                charge, information about categories of
                                                                                personal information (if any) we
                                                                                disclosed to third parties for direct
                                                                                marketing purposes and the names and
                                                                                addresses of all third parties with
                                                                                which we shared personal information in
                                                                                the immediately preceding calendar year.
                                                                                If you are a California resident and
                                                                                would like to make such a request,
                                                                                please submit your request in writing to
                                                                                us using the contact information
                                                                                provided below.</span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">If you are
                                                                                under 18 years of age, reside in
                                                                                California, and have a registered
                                                                                account with Services, you have the
                                                                                right to request removal of unwanted
                                                                                data that you publicly post on the
                                                                                Services. To request removal of such
                                                                                data, please contact us using the
                                                                                contact information provided below and
                                                                                include the email address associated
                                                                                with your account and a statement that
                                                                                you reside in California. We will make
                                                                                sure the data is not publicly displayed
                                                                                on the Services, but please be aware
                                                                                that the data may not be completely or
                                                                                comprehensively removed from all our
                                                                                systems (e.g.<bdt
                                                                                    class="block-component"></bdt>,<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> backups, etc.).<span
                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                        data-custom-class="body_text">
                                                                                        <bdt class="block-component">
                                                                                        </bdt>
                                                                                        </bdt>
                                                                                    </span></span></span></span></span></span></span></span></span></span></span>
                                                                    </bdt>
                                                                    </span></span></span></span></span></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="policyupdates" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">12.
                                                                                                DO WE MAKE UPDATES TO
                                                                                                THIS
                                                                                                NOTICE?</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><em><strong>In
                                                                                        Short: </strong>Yes, we will
                                                                                    update this notice as necessary to
                                                                                    stay compliant with relevant
                                                                                    laws.</em></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">We may
                                                                                update this privacy notice from time to
                                                                                time. The updated version will be
                                                                                indicated by an updated <bdt
                                                                                    class="block-component"></bdt>
                                                                                "Revised"<bdt
                                                                                    class="statement-end-if-in-editor">
                                                                                </bdt> date and the updated version will
                                                                                be effective as soon as it is
                                                                                accessible. If we make material changes
                                                                                to this privacy notice, we may notify
                                                                                you either by prominently posting a
                                                                                notice of such changes or by directly
                                                                                sending you a notification. We encourage
                                                                                you to review this privacy notice
                                                                                frequently to be informed of how we are
                                                                                protecting your
                                                                                information.</span></span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="contact" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">13.
                                                                                                HOW CAN YOU CONTACT US
                                                                                                ABOUT THIS
                                                                                                NOTICE?</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">If you
                                                                                have questions or comments about this
                                                                                notice, you may <span
                                                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                        data-custom-class="body_text">
                                                                                        <bdt class="block-component">
                                                                                        </bdt>email us at <bdt
                                                                                            class="question">
                                                                                            info@swiftrecharge.ng</bdt>
                                                                                        <bdt
                                                                                            class="statement-end-if-in-editor">
                                                                                        </bdt>
                                                                                    </span></span><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                            data-custom-class="body_text">
                                                                                            or by post
                                                                                            to:</span></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text"><span
                                                                                    style="font-size: 15px;"><span
                                                                                        style="color: rgb(89, 89, 89);"><span
                                                                                            style="color: rgb(89, 89, 89);"><span
                                                                                                data-custom-class="body_text">
                                                                                                <bdt class="question">
                                                                                                   Swift Recharge  inc</bdt>
                                                                                            </span></span></span></span></span><span
                                                                                data-custom-class="body_text"><span
                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                        data-custom-class="body_text">
                                                                                        <bdt class="block-component">
                                                                                        </bdt>
                                                                                    </span></span></bdt>
                                                                                </span></span></span></span></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text">
                                                                            <bdt class="question">4th avenue</bdt><span
                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px;">
                                                                                    <bdt class="block-component"></bdt>
                                                                                </span></span>
                                                                        </span></bdt></span></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text">
                                                                            <bdt class="question">ajah</bdt><span
                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px;">
                                                                                    <bdt class="block-component"></bdt>
                                                                                    <bdt class="block-component"></bdt>
                                                                                    <bdt class="block-component"></bdt>
                                                                                    <bdt class="block-component"></bdt>
                                                                                </span></span>
                                                                        </span></bdt></span></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px;"><span
                                                                            data-custom-class="body_text"><span
                                                                                style="font-size: 15px;"><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="color: rgb(89, 89, 89);">
                                                                                        <bdt class="block-component">
                                                                                        </bdt>
                                                                                    </span></span></span>
                                                                            <bdt class="question">Nigeria</bdt><span
                                                                                style="font-size: 15px;"><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="color: rgb(89, 89, 89);">
                                                                                        <bdt
                                                                                            class="statement-end-if-in-editor">
                                                                                            <span
                                                                                                style="font-size: 15px;"><span
                                                                                                    data-custom-class="body_text"><span
                                                                                                        style="color: rgb(89, 89, 89);">
                                                                                                        <bdt
                                                                                                            class="statement-end-if-in-editor">
                                                                                                            <span
                                                                                                                style="font-size: 15px;"><span
                                                                                                                    data-custom-class="body_text"><span
                                                                                                                        style="color: rgb(89, 89, 89);">
                                                                                                                        <bdt
                                                                                                                            class="statement-end-if-in-editor">
                                                                                                                        </bdt>
                                                                                                                    </span></span></span>
                                                                                                        </bdt>
                                                                                                        <bdt
                                                                                                            class="statement-end-if-in-editor">
                                                                                                        </bdt>
                                                                                                    </span></span></span><span
                                                                                                data-custom-class="body_text"><span
                                                                                                    style="color: rgb(89, 89, 89);"><span
                                                                                                        style="font-size: 15px;">
                                                                                                        <bdt
                                                                                                            class="statement-end-if-in-editor">
                                                                                                        </bdt>
                                                                                                    </span></span></span>
                                                                                        </bdt>
                                                                                    </span></span></span>
                                                                        </span><span data-custom-class="body_text"><span
                                                                                style="font-size: 15px;"><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="color: rgb(89, 89, 89);">
                                                                                        <bdt
                                                                                            class="statement-end-if-in-editor">
                                                                                            <span
                                                                                                style="color: rgb(89, 89, 89);"><span
                                                                                                    style="font-size: 15px;"><span
                                                                                                        data-custom-class="body_text">
                                                                                                        <bdt
                                                                                                            class="block-component">
                                                                                                            <bdt
                                                                                                                class="block-component">
                                                                                                            </bdt>
                                                                                                    </span></span></span>
                                                                                    </span></span></span><span
                                                                                style="font-size: 15px;"><span
                                                                                    data-custom-class="body_text"><span
                                                                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                            style="font-size: 15px;"><span
                                                                                                data-custom-class="body_text">
                                                                                                <bdt
                                                                                                    class="statement-end-if-in-editor">
                                                                                                    <bdt
                                                                                                        class="block-component">
                                                                                                    </bdt>
                                                                                            </span></span></div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div id="request" style="line-height: 1.5;"><span
                                                                        style="color: rgb(127, 127, 127);"><span
                                                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                        id="control"
                                                                                        style="color: rgb(0, 0, 0);"><strong><span
                                                                                                data-custom-class="heading_1">14.
                                                                                                HOW CAN YOU REVIEW,
                                                                                                UPDATE, OR DELETE THE
                                                                                                DATA WE COLLECT FROM
                                                                                                YOU?</span></strong></span></span></span></span></span>
                                                                </div>
                                                                <div style="line-height: 1.5;"><br></div>
                                                                <div style="line-height: 1.5;"><span
                                                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                                                data-custom-class="body_text">
                                                                                <bdt class="block-component"></bdt>Based
                                                                                on the applicable laws of your country,
                                                                                you may have the right to request access
                                                                                to the personal information we collect
                                                                                from you, change that information, or
                                                                                delete it. <bdt class="else-block">
                                                                                </bdt>To request to review, update, or
                                                                                delete your personal information, please
                                                                                <bdt class="block-component">
                                                                            </span></bdt><span
                                                                                data-custom-class="body_text">visit:
                                                                                <bdt class="question"><a
                                                                                        href="http://www.swiftrecharge.ng"
                                                                                        target="_blank"
                                                                                        data-custom-class="link">http://www.swiftrecharge.ng</a>
                                                                                </bdt>
                                                                                <bdt class="else-block"></bdt>
                                                                            </span></span><span
                                                                            data-custom-class="body_text">.</span></span></span>
                                                                </div>
                                                                <style>
                                                                    ul {
                                                                        list-style-type: square;
                                                                    }

                                                                    ul>li>ul {
                                                                        list-style-type: circle;
                                                                    }

                                                                    ul>li>ul>li>ul {
                                                                        list-style-type: square;
                                                                    }

                                                                    ol li {
                                                                        font-family: Arial;
                                                                    }
                                                                </style>
                                                            </div>`


    return (
        <div className=' appear-in' style={{ height: "100%", color: "#FF731599 !important", padding: "50px 25px", textAlign: "center !important", backgroundColor: "#ffffff90" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(theHTML) }}></div>
    );
}