export const SliderData = [
    {
      image:
        'https://images.unsplash.com/photo-1546768292-fb12f6c92568?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
        name: `Chinedu Okonkwo.`,
        header: `Satisfied Customer: My Swift Recharge Experience`,
        text: `I can't express how convenient Swift Recharge has made my life. With their Instant Airtime Recharge, I can stay connected effortlessly with just a few clicks. No more hassle of searching for recharge cards. It's a game-changer!`
    },
    {
      image:
        'https://images.unsplash.com/photo-1501446529957-6226bd447c46?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1489&q=80',
        name: `Amina Ibrahim.`,
        header: `Delighted with Customizable Recharge Amounts`,
        text: `I love Swift Recharge's Customizable Recharge Amounts. I can choose the exact recharge amount I need, whether it's for a casual chat or a specific usage requirement. It's tailored to my needs!`
    },
    {
      image:
        'https://images.unsplash.com/photo-1483729558449-99ef09a8c325?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80',
        name: `Ahmed Abdullahi.`,
        header: `Seamless Connection with Airtime Vault`,
        text: `I'm impressed with Swift Recharge's Airtime Vault feature. It's like having a safety net for my airtime. Now, I never have to worry about running out of credit, and I can access my reserve airtime both online and offline. It's truly uninterrupted communication!`
   
    },
    {
      image:
        'https://images.unsplash.com/photo-1475189778702-5ec9941484ae?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1351&q=80',
        name: `Ngozi Okafor.`,
        header: `Swift Recharge's Recharge Card 2.0`,
        text: `I used to struggle with traditional recharge cards, but Swift Recharge's Recharge Card 2.0 has made my life so much easier. Whether I want to top up online or offline, I have the flexibility and convenience I need. It's a fantastic system!`
   
    },
    {
      image:
        'https://images.unsplash.com/photo-1503177119275-0aa32b3a9368?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80',
        name: `Tunde Adekunle.`,
        header: `Stress-Free Bill Payments!`,
        text: `Tunde Adekunle, and Swift Recharge is my go-to for all my bill payments. With their latest financial technology, I never miss a payment deadline again. The timely reminders keep me on top of my bills, and I can pay over 8000+ billers from various sectors with ease. It's secure, reliable, and incredibly convenient!`
   
    }
  ];